@import "variables";

:root {
  --color: #{$color};

  --navbar-bg-color: #{$navbar-bg-color};
  --navbar-font-color: #{$navbar-font-color};
  --brand-font-size: #{$brand-font-size};
  --brand-family: #{$brand-family};
  --brand-font-color: #{$brand-font-color};

  --card-title-size: #{$card-title-size};

  --hero-bg-color: #{$hero-bg-color};
  --hero-color: #{$hero-color};
  --hero-color: #{$hero-color};
}

html {
  padding-top: $has-fixed-top;
  height: auto;
  width: auto;
  height: 100%;
  width: 100%;
}

body {
  position: relative;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

#root {
  position: relative;
  margin: 0;
  padding: 0;
  height: auto;
  width: 100vw;
  height: 100%;
  width: 100%;
}

.page {
  height: 100%;
  width: 100%;
}

main {
  position: relative;
  min-height: 80vh;
  background-color: $main-bg-color;
  background-image: $linear-gradient;
  color: $main-color;
}

footer {
  padding-top: 4.8rem !important;
  padding-bottom: 4.8rem !important;
  padding-left: 3.6rem !important;
  padding-right: 3.6rem !important;
  min-height: 60px;
  color: $light;
  background-color: $footer-bg-color !important;

  p {
    margin: 0;
    padding: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
a {
  text-shadow: $text-shadow;
}

.lead {
  font-weight: 500;
}

.icon svg {
  filter: drop-shadow($text-shadow);
}

.navbar {
  @extend .d-flex;
  @extend .navbar, .navbar-expand-md, .navbar-light;

  background-color: var(--navbar-bg-color) !important;
  background-image: $linear-gradient;
  color: var(--navbar-font-color);

  .navbar-brand {
    @extend .ps-3;

    h3,
    .icon {
      color: var(--brand-font-color);
      background: var(--brand-bg-color);
      font-size: var(--brand-font-size);
      font-family: var(--brand-family);
      text-shadow: $text-shadow;
    }

    h3 {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  .nav-link {
    color: $navbar-font-color !important;
    font-weight: 500 !important;
  }

  .nav-link:hover {
    background: $navbar-link-hover-bg !important;
    color: $navbar-link-hover !important;
  }

  .nav-link.active {
    background: $navbar-link-active-bg !important;
    color: $navbar-link-active !important;
  }

  .nav-link.active:hover {
    background: $navbar-link-active-hover-bg !important;
    color: $navbar-link-active-hover !important;
  }
}

.hero {
  @extend .p-3;
  position: relative;
  min-height: 240px;
  background-color: $hero-bg-color;
  background-image: $linear-gradient;
  padding-top: 12rem !important;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  * {
    z-index: 990;
  }

  .photo {
    @extend .img-fluid;
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  p,
  .lead {
    color: $hero-color !important;
    font-size: $hero-font-size !important;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.hero-bg-image {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  display: flex;
  // align-items: end;

  img {
    z-index: 0;
    position: relative;
    min-height: 120%;
    width: 120%;
  }
}

.hero-bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 980;
}

.hero-title {
  display: flex;
  align-items: baseline;

  h2,
  .icon {
    color: $hero-headings-color !important;
    font-size: $hero-headings-size !important;
    font-weight: $hero-headings-weight !important;
    margin-bottom: 0 !important;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.hero-text {
  color: $light !important;
}

.heading {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $headings-color !important;
  }

  .lead {
    color: $headings-lead-color !important;
  }
}

.card {
  background-color: $card-bg-color;
  background-image: $linear-gradient;
}

.card-title {
  display: flex;
  align-items: baseline;

  h3,
  .icon {
    color: $card-title-color !important;
    font-size: $card-title-size !important;
    margin-bottom: 0 !important;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.card-button {
  .btn {
    color: $card-button-color !important;
    background-color: $card-button-bg-color !important;

    &:hover {
      background-color: $card-button-hover-bg !important;
      box-shadow: none !important;
    }
  }
}

@media only screen and (max-width: 420px) {
  .hero {
    padding-top: 10rem !important;
  }
  .hero-bg-image {
    justify-content: center;
    align-items: center;

    img {
      z-index: 0;
      position: relative;
      height: 120%;
      width: auto;
    }
  }
}
